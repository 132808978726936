/* eslint-disable max-len */
export type FeatureFlag<Key extends string> = {
  key: Key;
  description: string;
  createdAt?: `${string}/${string}/${string}`;
};

export const featureFlags = [
  {
    key: 'additionalSchedulingOptions',
    description:
      'Allow the usage of additional monthly scheduling options for audiences on the export modal',
  },
  {
    key: 'allowAdvancedDelay',
    description: '',
  },
  {
    key: 'audienceScheduleOnExportModal',
    description:
      'Allow the usage of scheduling feature for audiences on the export modal',
  },
  {
    key: 'showEvaluationLimit',
    description:
      'Show the fields under the evaluation settings to limit the number of criteria records passing forwards per column',
  },
  {
    key: 'datasetCreatePage',
    description: `Enable the pages for dataset creation and editing,
      instead of using the related modals`,
  },
  {
    key: 'datasetGroupsCreatePage',
    description: `
    Turn on the new page for creating/editing a dataset group
    that should replace the current dataset group create/edit modals.
    `,
    createdAt: '2024/02/28',
  },
  {
    key: 'datasetTypesExtendedListEnabled',
    description: `
      When turned on, enable the complete list of dataset types.
      When turned off, limit the dataset types to only USER, TRANSACTION and EVENT.
    `,
    createdAt: '2024/03/22',
  },
  {
    key: 'datasetTypesSelectorComboboxEnabled',
    description:
      'Change the dataset type selector from the cards to a simple select.',
    createdAt: '2024/04/01',
  },
  {
    key: 'duplicateResolutionMethodEnabled',
    description:
      'When enabled, allow users to create a dataset without a unique key',
    createdAt: '2024/04/09',
  },
  {
    key: 'enhancedMatchRate',
    description: 'Show enhanced match rate option on export v2',
  },
  {
    key: 'computedFieldsEnabled',
    description: 'Computed Fields Enabled',
  },
  {
    key: 'isMlbOrg',
    description: '',
  },
  {
    key: 'journeyBuilder',
    description: 'Journey Builder enabled',
  },
  {
    key: 'joinCardinalitySelectionEnabled',
    description:
      'On datasets join configuration, allows the join cardinality selection, i.e., if the join is a one-one, one-to-many, many-to-one, many-to-many relation',
    createdAt: '2024/04/20',
  },
  {
    key: 'joinConfigurationOnModelsPageEnabled',
    description: `
      Allow the user to set a join configuration between a dataset and a computed field (Model with User Trait type) directly on the Models page.
      This FF also hides the join key, as it is not required.
      Created at 2024/08/09'
    `,
    createdAt: '2024/08/09',
  },
  {
    key: 'fullAudienceRefreshEnabled',
    description: `
      Allows for the full audience to be sent to a destination for all destinations. Not just the delta in audience'
    `,
    createdAt: '2024/08/09',
  },
  {
    key: 'leftDatasetSelectionEnabled',
    description:
      'On datasets join configuration, allows the selection of any left dataset, not necessarily the customer_details one',
    createdAt: '2024/04/20',
  },
  {
    key: 'marveEnabled',
    description: 'Enable marve options on dataset group pages',
  },
  {
    key: 'manyToManyCardinalityEnabled',
    description:
      'Includes the many to many option under the join cardinalities',
    createdAt: '2024/04/20',
  },
  {
    key: 'modelsAsDatasetsEnabled',
    description: 'Allow selecting Models under create dataset form',
    createdAt: '2024/08/12',
  },
  {
    key: 'primaryReadySelectorEnabled',
    description: `
      Allow any dataset type to be the primary dataset of a dataset group.
      When not enabled, the code will enforce customer_details as the primary dataset,
      guaranteeing backward compatibility.
    `,
    createdAt: '2024/03/27',
  },
  {
    key: 'piiFeatureEnabled',
    description:
      'Enable the declaration of pii fields on the dataset configuration',
  },
  {
    key: 'criteriaCustomAttributeFormula',
    description: 'enables formula type for custom attributes on criteria node',
  },
  {
    key: 'destinationCustomAttributeFormula',
    description:
      'enables formula type for custom attributes on destination node',
  },
  {
    key: 'variationCustomAttributeFormula',
    description: 'enables formula type for custom attributes on variation node',
  },
  {
    key: 'entryCustomAttributeFormula',
    description: 'enables formula type for custom attributes on entry node',
  },
  {
    key: 'showAdditionalFields',
    description: 'Show Personalization V2 Component',
  },
  {
    key: 'showAudienceScheduleOnDestinationNode',
    description: 'Enable user to configure audience export schedule',
  },
  {
    key: 'showCriteriaEvaluation',
    description: 'Enable user to configure criteria evaluation window',
  },
  {
    key: 'showExperiments',
    description: 'Experiment Node activated',
  },
  {
    key: 'showCRM',
    description: 'CRM Node activated',
  },
  {
    key: 'showExportDuration',
    description: '',
  },
  {
    key: 'showGlobalExit',
    description: 'Enable global exit audiences on journey',
  },
  {
    key: 'showNestedMatchFields',
    description: 'Show Nested Match Fields Component',
  },
  {
    key: 'showReentry',
    description: 'Enable user reentry on journey',
  },
  {
    key: 'showReportingTooltip',
    description: 'tooltip for journeys metrics on report chips',
  },
  {
    key: 'audienceCustomAttributes',
    description: 'Allow setting custom attributes (key-value) on audiences',
  },
  {
    key: 'audienceQueryLimitAndOrder',
    description: 'show thew query limit, rank and sort in audience settings',
  },
  {
    key: 'showSimulate',
    description: '',
  },
  {
    key: 'bulkExportAudiences',
    description:
      'show the action button to download audiences in bulk from the index page',
  },
  {
    key: 'bulkArchiveAudiences',
    description:
      'show the action button to archive audiences in bulk from the index page',
    createdAt: '2024/09/18',
  },
  {
    key: 'bulkDeleteAudiences',
    description:
      'show the action button to delete audiences in bulk from the index page',
    createdAt: '2024/09/18',
  },
  {
    key: 'bulkExportJourneys',
    description:
      'show the action button to download journeys in bulk from the index page',
    createdAt: '2024/09/20',
  },
  {
    key: 'bulkArchiveJourneys',
    description:
      'show the action button to archive journeys in bulk from the index page',
    createdAt: '2024/09/20',
  },
  {
    key: 'bulkDeleteJourneys',
    description:
      'show the action button to delete journeys in bulk from the index page',
    createdAt: '2024/09/20',
  },
  {
    key: 'showStepActivityTable',
    description: 'show step activity table',
    createdAt: '2024/04/24',
  },
  {
    key: 'audienceExportWritePermission',
    description: '',
  },
  {
    key: 'audienceTemplateWritePermission',
    description: '',
  },
  {
    key: 'advancedJoins',
    description: '',
  },
  {
    key: 'allowFieldAliasing',
    description: 'Turn on the field aliasing feature',
  },
  {
    key: 'canEditLockedTemplates',
    description: 'Can edit locked templates',
  },
  {
    key: 'lockedTemplatesEnabled',
    description: 'enable locked templates',
  },
  {
    key: 'showDestinationNodeAttributes',
    description: 'Show custom attributes in the destination node settings tab.',
  },
  {
    key: 'showCriteriaNodeAttributes',
    description: 'Show custom attributes in the criteria node settings tab.',
  },
  {
    key: 'showEntryNodeAttributes',
    description: 'Show custom attributes in the entry node settings tab.',
  },
  {
    key: 'showVariationNodeAttributes',
    description: 'Show custom attributes in the variation node settings tab.',
  },
  {
    key: 'pageViewTiming',
    description: 'Allows for NewRelic page view timing.',
  },
  {
    key: 'sessionTracing',
    description: 'Allows for NewRelic session tracing.',
  },
  {
    key: 'showOpportunityStageAndRecordType',
    description:
      'Show Opportunity Stage and Record Type fields in CRM Action Form',
    createdAt: '2024/09/11',
  },
  {
    key: 'displayV2flowCSV',
    description: 'Display V2 flow for CSV exports instead of V1',
    createdAt: '2024/04/25',
  },
] as const;

export type FeatureFlagKey = (typeof featureFlags)[number]['key'];

if (__DEV__) {
  const set = new Set<string>();
  for (const flag of featureFlags) {
    if (set.has(flag.key)) {
      console.warn(`Feature flag "${flag.key}" is duplicated`);
    }
    set.add(flag.key);
  }
}
